import React, { useState } from 'react'
import * as S from './styles'
import { Form, Button, notification, Spin } from 'antd';
import Step from './Steps/Step';
import FinishedStep from './Steps/Finished'
import Validate from '../../utils/validates'
import Vtex from '../../utils/vtex';
import MovDesk from '../../utils/movdesk';
import CONSTS from '../../utils/consts';

const HOST = 'drogariapacheco';
//const HOST = 'drogariasp';

let BASE_URL = 'https://a7ve0w4wl6.execute-api.us-east-1.amazonaws.com/dev/api/v3/storage/prescription/'

const FormSteps = () => {
  const [form] = Form.useForm();
  const [finished, setFinished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [protocol, setProtocol] = useState(null)
  const [fileUrl, setFileUrl] = useState('');
  let ticketDetail = null;

  async function save() {
    try{
      const formData = await form.validateFields()
      await setLoading(true)
      if(validate(formData.orderCPF, formData.orderContactType, formData.orderContact, formData.orderEmail)) {
        if(formData.orderNumber) {
          const hasOrder = await validateOrderNumber(formData.orderNumber, formData.orderCPF)
          if(!hasOrder.length) throw new Error('PEDIDO_NAO_ENCONTRADO')
          const ticket = await MovDesk.getByOrderNumber(formData.orderNumber, formData.orderCPF)
          if(ticket) { 
            ticketDetail = await MovDesk.getProtocolTicket(ticket.id)
            await updateTicket(ticketDetail, formData.orderName, formData.orderSoli, formData.orderAss, formData.orderMsg)
          } else {
            ticketDetail = await createTicket(formData)
          }
        } else {
          ticketDetail = await createTicket(formData)
        }
        notification.success({
          description: 'Registrado com sucesso',
          message: 'Sucesso'
        })
        await setProtocol(ticketDetail.id)
        await setFinished(true)
      }
    }catch(error) {
      if(error.message === 'PEDIDO_NAO_ENCONTRADO') {
        notification.error({
          description: 'Não foi possível encontrar o pedido.',
          message: 'Atenção'
        })
        form.setFields([{
          name: 'orderNumber',
          errors:['Pedido inválido']
        }])
      } 
    } finally {
      await setLoading(false)
    }
  }

  async function updateTicket(ticket, clientName, solicitacao, assunto, mensagem) {
    const resp = await MovDesk.addTicketAction(ticket.id, clientName, solicitacao, assunto,mensagem)
    return resp
  }
  
  async function createTicket(data) {
    try {
      const TODAS_SOLICI = [...CONSTS.SOLICITACOES_LOJA_ONLINE, ...CONSTS.SOLICITACOES_LOJA_FISICA]
      const SOLI_SELECIONADA = TODAS_SOLICI.filter((soli) => Number(soli.value) === Number(data.orderAss))
      let clientMovDesk = await MovDesk.getClientsbyCPF(data.orderCPF, HOST)
      const orderNumber = data.orderNumber
      if(!clientMovDesk) { 
        clientMovDesk = await MovDesk.createClient(data)
      }
      // verificar se existe numero de pedido para inserir
      if(orderNumber) data['numeroPedido'] = orderNumber

      // Formatando data
      data['clientID'] = clientMovDesk.id
      data['statusSmart'] = CONSTS.statusSmart[data.orderAss]
      data['assunto'] = CONSTS.ASSUNTOS[data.orderAss][0].name
      data['solicitacao'] = SOLI_SELECIONADA[0].name
      data['localSolicitacao'] = CONSTS.LOCAL_SOLICITACO[data.typeTicket]
      data['SIGLA'] = HOST === 'drogariasp' ? 'DSP' : 'DPA'
      data['arquivo'] = fileUrl;
      data['descricao'] = CONSTS.gettemplateHtmlEmail({
        clientName: data.orderName, 
        solicitacao: data.orderSoli, 
        assunto: data.orderAss, 
        mensagem: data.orderMsg,
        arquivo: fileUrl !== '' ?  `<a href="${fileUrl}">Clique aqui para baixar o anexo!</a>` : null
      })
      
      //Pegando layout de envio
      const dataSend = CONSTS.getFormatedTicketData(data)
      
      // Criar novo ticket
      const resp = await MovDesk.createTicket(dataSend, HOST)
      return resp
    } catch (error) {
      console.log('erro ao criar cliente', error)
    }

  }

  function validate(cpf, contactType, contact, email) {
    const isValidContact = validateContact(contactType, contact)
    const isValidCpf = validateCpf(cpf);
    const isValidEmail = validateEmail(email)
    return (isValidContact && isValidCpf && isValidEmail)
  }

  function validateContact(contactType, contact) {
    const isValid = Validate.validateContact(contactType, contact)
    if(!isValid) {
      form.setFields([{
        name: 'orderContact',
        errors:['telefone inválido']
      }])
      return false
    }
    return true
  }
  
  function validateCpf(cpf) {
    const isValid = Validate.validateCPF(cpf)
    if(!isValid) {
      form.setFields([{
        name: 'orderCPF',
        errors:['CPF inválido']
      }])
      return false
    }
    return true
  }

  function validateEmail(email) {
    const isValid = Validate.validateEmail(email)
    if(!isValid) {
      form.setFields([{
        name: 'orderEmail',
        errors:['email inválido']
      }])
      return false
    }
    return true
  }

  async function validateOrderNumber(orderNumber, cpf) {
    const resp = await Vtex.getOrder(orderNumber, cpf)
    return resp
  }

  async function handleGetFile(res, fileList) {
    if (res && fileList.length) {
        const { data: { file } } = res;
        setFileUrl(file)
    }
  }

  async function handleDeleteThumb() {
    try {
        await setLoadingDelete(true)
        await fetch(`${BASE_URL}`, {
            method: 'DELETE',
            headers: {
                'x-api-token': '1b2554b6466ceebdabe6ec8e8ab74f88d20f7be0cd08d38e8c1627c6076b2c8f',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'file_name': fileUrl.replace('https://smartnx-clients-storage.s3.sa-east-1.amazonaws.com/', '')}),
        });
        await setFileUrl('')
        await setLoadingDelete(false)
    } catch (error) {
        console.log('erro ao deletar arquivo', error);
    }
  }

  return (
    <S.Wrapper>
      <S.WrapperHeader host={HOST}>
        { HOST === 'drogariapacheco' && <a href="https://www.drogariaspacheco.com.br/"><img src="https://i.ibb.co/7y8Bp6R/marca-pach2.png" alt="logo pacheco" style={{height: '60%', width: '11%'}} /></a>}
        { HOST === 'drogariasp' && <a href="https://www.drogariasaopaulo.com.br/"><img src="https://i.ibb.co/18Psbsw/DSP-Logo-pref2-01.png" alt="logo dpsp"  style={{height: '90%', width: '12%'}}/></a>}
      </S.WrapperHeader>
      <S.WrapperBackground host={HOST}/>
      <S.WrapperBody host={HOST}>
        <S.WrapperSteps>
          <div className="steps-content">
            {!finished && (
              <Step
                form={form}
                host={HOST}
                handleGetFile={({ file, fileList } ) => handleGetFile(file.response, fileList )}
                handleDeleteThumb={handleDeleteThumb}
                loadingDelete={loadingDelete}
              /> 
            )}
            {finished && <FinishedStep protocol={protocol}/> }
          </div>
          <div className="container-buttons-step">
          {(!loading && !finished) && <Button onClick={async () => await save()}>Enviar</Button>}
          {loading && <Spin />}
          </div>
        </S.WrapperSteps>
      </S.WrapperBody>
      <S.WrapperFooter host={HOST}/>
    </S.Wrapper>
  )
}

export default FormSteps;