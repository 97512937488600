import React from 'react'
import * as S from './styles'
import { Typography  } from 'antd';

const { Title, Text } = Typography;
const FinishedStep = ({ protocol }) => {
  return (
    <S.Wrapper>
      <S.Title>Fale Conosco:</S.Title>
      <Title level={5}>Solicitação enviada com sucesso!</Title>
      <Text>Por favor, acompanhe nosso retorno através do e-mail informado aqui.</Text>
      <Text>O nosso tempo médio de tratativa é de até 24h.</Text>
      <Text>Seu número de protocolo é <b>{protocol}</b>.</Text>
      <Text>Agradecemos o seu contato 😉.</Text>
    </S.Wrapper>
  )
}

export default FinishedStep