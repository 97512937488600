import './global.css';
import Form from './pages/Form'
import 'antd/dist/antd.css'

function App() {
  return (
    <Form />
  );
}
export default App;
