class Api {
  constructor(url, xApi) {
    this.urlBase = url;
    this.xApi = xApi
  }
  async get(url, params = {}) {
    let _p = '';
    if (params) {
      _p = this.serialize(params);
    }
    const headers = this.headers({});
    const response = await fetch(`${this.urlBase}/${url}${_p ? `?${_p}` : ''}`, {
      headers,
    }).then(async (res) => {
      return res.json();
    });
    return response;
  }
  async post(url, params) {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    return fetch(`${this.urlBase}/${url}`, {
      "method": "POST",
      headers,
      "body": JSON.stringify(body),
    }).then(async (res) => {
      return res.json();
    });
  }
  async patch(url, params) {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    return fetch(`${this.urlBase}/${url}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body),
    }).then(async (res) => {
      return res.json();
    });
  }
  async put(url, params) {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    return fetch(`${this.urlBase}/${url}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    }).then(async (res) => {
      return res.json();
    });
  }
  async delete(url, params) {
    let body = {};
    if (params) body = params;
    const headers = this.headers({});
    return fetch(`${this.urlBase}/${url}`, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(body),
    }).then(async (res) => {
      return res.json();
    });
  }
  serialize(obj) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    }
    return str.join('&');
  }
  headers(headers) {
    headers = {
      ...headers,
      'content-type': 'application/json',
      'token': this.xApi,
    };
    return headers;
  }
}

export default Api;
