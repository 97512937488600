import styled from 'styled-components'

export const Title = styled.div`
  display: flex;
  color: rgb(134, 128, 128);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.6;
`;

export const SubTitle = styled.div`
 color: rgb(134, 128, 128);
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: justify;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  max-height: 100%;
  min-height: 200px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  margin-top: 10px;
  min-width: 30vw;
  border-radius: 10px;
  overflow-x: hidden;
  .containerLoading {
    position: absolute;
    top: 5px;
    z-index: 9999;
    right: 10px;
  }
`;

export const WrapperContacts = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
  justify-items: center;
`;
export const WrapperIcon = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  height:50px;
  
  a {
    color: #323232;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-items: center;
  }

  svg {
    font-size: 35px;
  }
`;

export const WrapperUpload = styled.div`
  display: flex;
  flex-direction: row;
`;