import ApiInstance from './api'
import Consts from './consts'

const Api = new ApiInstance('https://api.movidesk.com/public/v1')
const ApiSaoPaulo = new ApiInstance('https://smart-api.smartnx.io', '74e78942d3b40abac2ae3d3c27c90fd18231bea286fec795a7740416f2b7d65c')
const ApiPacheco = new ApiInstance('https://smart-api.smartnx.io', '1b2554b6466ceebdabe6ec8e8ab74f88d20f7be0cd08d38e8c1627c6076b2c8f')

class MovDesk {
  async getByOrderNumber(number, cpf) {
    let result = null
    const formatedCpf = cpf.split('.').join('').replace('-','')
    const resp = await Api.get(`tickets?token=19634563-f9dd-4a69-9e9a-98a8be041943&$select=id,subject,createdDate,status,tags,actions,clients&$EXPAND=customfieldvalues,clients&$filter=customFieldValues/any(y: y/customFieldId eq 20501 and y/value eq '${number}')`)
    if(!resp.length) return result
    const allClients = resp.map((ticket) => ticket.clients[0].id)
    const normalizedClients = new Set(allClients)
    const clients = []
    for (let idClient of normalizedClients) {
      const client = await this.getClientsData(idClient)
      clients.push(client)
    }
    const findClient = clients.filter((client) => String(client.cpfCnpj) === String(formatedCpf))
    if(findClient.length) {
      result = resp.filter((ticket) => ticket.clients[0].id === findClient[0].id)
      result = result[0]
    }
    return result
  }

  async getProtocolTicket(ticketID, host) {
    try {
      let result = null;
      if(host === 'drogariasp') {
        result = await ApiSaoPaulo.get(`api/v1/helpdesk/tickets/${ticketID}`, { company_id: "6" })
      } else {
        result = await ApiPacheco.get(`api/v1/helpdesk/tickets/${ticketID}`, { company_id: "8" })
      }
      if(result.data) return result.data
      return null
    } catch (error) {
      console.log('erro ao pegar protocolo', error.message)
    }
  }
  
  async getClientsData(clientID) {
    const resp = await Api.get(`persons?token=19634563-f9dd-4a69-9e9a-98a8be041943&$filter=id eq '${clientID}'`)
    if(resp.length) return resp[0]
    return null
  }

  async getClientsbyCPF(cpf, host) {
    let result = null
    const formatedCpf = cpf.split('.').join('').replace('-','')
    try {
      if(host === 'drogariasp') {
        result = await ApiSaoPaulo.get('api/v1/helpdesk/clients', {doc: formatedCpf,  company_id: "6" })
      } else {
        result = await ApiPacheco.get('api/v1/helpdesk/clients', {doc: formatedCpf,  company_id: "8" })
      }
      if(result.data) return result.data[0]
      return null
    }catch (error) {
      return null
    }
   
  }

  async createClient(data, host) {
    let result = null
    const dataMovDesk = {
      isActive: true,
      personType: 1,
      profileType: 2,
      cpfCnpj: data.orderCPF,
      businessName: data.orderName,
      emails: [{ emailType: 'Pessoal', email: data.orderEmail, isDefault: true }],
      relationships: [{id: "2085332231", allowAllServices: true, forceChildrenToHaveSomeAgreement: false}]
    }
    if(host === 'drogariasp') {
      result = await ApiSaoPaulo.post(`api/v1/helpdesk/clients`, {...dataMovDesk, company_id: "6"})
    } else {
      result = await ApiPacheco.post(`api/v1/helpdesk/clients`, {...dataMovDesk, company_id: "8"})
    }
    return result
  }

  async addTicketAction(ticketID, clientName, solicitacao, assunto,mensagem, host) {
    let result
    if(host === 'drogariasp') {
      result = await ApiSaoPaulo.put(`api/v1/helpdesk/tickets/${ticketID}`, { company_id: "6", actions: [{ type: 2, description: Consts.gettemplateHtmlEmail({ clientName, solicitacao, assunto,mensagem }) }] })
    } else {
      result = await ApiPacheco.put(`api/v1/helpdesk/tickets/${ticketID}`, { company_id: "8", actions: [{ type: 2, description: Consts.gettemplateHtmlEmail({ clientName, solicitacao, assunto,mensagem }) }] })
    }
    return result
  }

  async createTicket(data, host) {
    let result = null
    if(host === 'drogariasp') {
      result = await ApiSaoPaulo.post(`api/v1/helpdesk/tickets/direct`, {...data, company_id: "6"})
    } else {
      result = await ApiPacheco.post(`api/v1/helpdesk/tickets/direct`, {...data, company_id: "8"})
    }
    return result.data
  }

}


const MovDeskInstance = new MovDesk()
export default MovDeskInstance
