class Validates {

  validateCPF(cpf) {
    const formatedCpf = cpf.split('_').join('').split('.').join('').replace('-', '')
    return formatedCpf.length === 11
  }
  validateContact(type, value) {
    const formatedContact = value.split('_')
      .join('').split('.').join('').replace('-', '')
      .replace('(', '').replace(')', '').replace(' ','')
      if(String(type) === "1" && formatedContact.length !== 11) return false
      if(String(type) === "2" && formatedContact.length !== 10) return false
      return true
  }

  validateEmail(email) {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexEmail.test(email)
  }
}
const validatesInstance = new Validates();

export default validatesInstance
