import React, {useState} from 'react'
import CONSTS from '../../../utils/consts'
import * as S from './styles'
import { Form, Input, Select, Radio, Upload, Button, Tooltip, Spin } from 'antd';
import { UploadOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input'
import { useEffect } from 'react';
const { TextArea } = Input;
const { Option } = Select;

let BASE_URL = 'https://a7ve0w4wl6.execute-api.us-east-1.amazonaws.com/dev/api/v3/storage/prescription/'

const Step = ({ form, host, handleGetFile, handleDeleteThumb, loadingDelete }) => {

    const [assuntosLiberados, setAssuntosLiberados] = useState([])
    const [place, setPlace] = useState(null)
    const [maskContact, setMaskContact] = useState('1')
    const [contact, setContact] = useState(null)
    const [solicitacoes, setSolicitacoes] = useState([])
    const [fileOption, setFileOption] = useState(false)

    useEffect(() => {
        if(contact === "1") {
            setMaskContact('(11) 11111-1111')
            form.setFieldsValue({orderContact: ''})
        } else if(contact === "2") {
            setMaskContact('(11) 1111-1111')
            form.setFieldsValue({orderContact: ''})
        } else {
            setMaskContact('1')
            form.setFieldsValue({orderContact: ''})
        }
    }, [contact])
    
    function handleChangeSolici(value) {
        const assunto = CONSTS.ASSUNTOS[value]
        setAssuntosLiberados(assunto)
        form.setFieldsValue({orderAss: assunto[0].value})

        
        const soliValue = place === '2' && value === 12 ? true : false
        setFileOption(soliValue);
    }

    async function handleChangePlace(event) {
        const { value } =  event.target
        form.setFieldsValue({orderAss: null})
        form.setFieldsValue({orderSoli: null})
        form.setFieldsValue({ typeTicket: value })
        await setPlace(value)
        if(value === '1') {
            await setSolicitacoes(CONSTS.SOLICITACOES_LOJA_FISICA)
        } else {
            await setSolicitacoes(CONSTS.SOLICITACOES_LOJA_ONLINE)
        }
    }
    
    function handleContact(value) {
        setContact(value)
    }

    function normalizeOrderNumber(value) {
        const normalizedValue = value.replace(/[a-zA-Z]+/g, '')
        return normalizedValue
    }
    
    return (
    <S.Wrapper>
     <S.Title>FORMULÁRIO: FALE CONOSCO</S.Title>
      <S.SubTitle>
        Para agilizar o seu atendimento precisamos que você preencha os campos abaixo,<br/> ou 
        {host === 'drogariapacheco' && <a href="https://www.drogariaspacheco.com.br/"> clique aqui </a>} 
        {host === 'drogariasp' && <a href="https://www.drogariasaopaulo.com.br/"> clique aqui </a>} 
        para voltar ao site.
      </S.SubTitle>
        <br/>
    <Form name="secondForm-noOrder" layout="vertical" form={form}>
        <Form.Item
          style={{textAlign: 'left'}}
          name="typeTicket"
          rules={[{ required: true, message: 'Campo obrigatório'  }]}
        >
            <Radio.Group onChange={handleChangePlace} value={place}>
                <Radio value="1">Loja Física</Radio>
                <Radio value="2">Loja Online</Radio>
            </Radio.Group>
        </Form.Item>
      <Form.Item
          name="orderName"
          rules={[{ required: true, message: 'Campo obrigatório'  }]}
      >
         <Input placeholder="Nome"/>
      </Form.Item>
      <Form.Item
          name="orderCPF"
          rules={[{ required: true, message: 'Campo obrigatório'  }]}
      >
         <MaskedInput mask="111.111.111-11" placeholder="CPF"/>
      </Form.Item>
      <div style={{display: 'flex'}}>
      <Form.Item
          name="orderContactType"
          rules={[{ required: true, message: 'Campo obrigatório'  }]}
          style={{flex: 1, marginRight: '5px'}}
      >
          <Select
            placeholder="Tipo de contato"
            onChange={handleContact}>
             <Option value="1">Celular</Option>
             <Option value="2">Telefone</Option>
          </Select>
      </Form.Item>
      <Form.Item
          name="orderContact"
          rules={[{ required: true, message: 'Campo obrigatório', min: 2  }]}
          style={{flex: 1}}
      >
         <MaskedInput mask={maskContact} placeholder="Contato"/>
      </Form.Item>
      </div>
      <Form.Item
          name="orderEmail"
          rules={[{ required: true, message: 'Campo obrigatório'  }]}
      >
         <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
          name="orderSoli"
          rules={[{ required: true, message: 'Campo obrigatório'  }]}
      >
          <Select
            placeholder="Solicitação"
            onChange={handleChangeSolici}>
             {solicitacoes.length && solicitacoes.map((solicitacao, key) =>  <Option value={solicitacao.value} key={solicitacao.value}>{solicitacao.name}</Option>)}
          </Select>
      </Form.Item>

      <Form.Item
          name="orderAss"
          rules={[{ required: true, message: 'Campo obrigatório'  }]}
      >
          <Select
            placeholder="Assunto">
             {assuntosLiberados.length && assuntosLiberados.map((assunto, key) =>  <Option value={assunto.value} key={assunto.value}>{assunto.name}</Option>)}
          </Select>
      </Form.Item>

      {fileOption && (
            <Form.Item
              name="fileList"
              valuePropName="fileList"
              getValueFromEvent={() => {}}
            >
                <S.WrapperUpload>
                    <Upload
                        name="file"
                        action={`${BASE_URL}`}
                        method="POST"
                        headers={{'x-api-token': '1b2554b6466ceebdabe6ec8e8ab74f88d20f7be0cd08d38e8c1627c6076b2c8f'}}
                        data={(file) => {
                            const formData = new FormData();
                            formData.append('file', file);
                            return { body: formData };
                        }}
                        onChange={handleGetFile}
                        onRemove={handleDeleteThumb}
                        style={{ padding:  '0px'}}
                        maxCount={1}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button icon={<UploadOutlined />}>Adicionar anexo</Button>
                            {!loadingDelete ? (
                                <Tooltip title="Em respeito à LGPD, para cumprimento de obrigação legal, o Grupo DPSP coletará uma via da receita para envio dos medicamentos comprados na plataforma de E-commerce, respeitando os prazos de retenção exigidos por lei, de acordo com a sua política de privacidade.">
                                    <InfoCircleOutlined style={{ display: 'flex', alignSelf: 'center', marginLeft: '10px' }} />
                                </Tooltip>
                            ) : (
                                <Spin indicator={<LoadingOutlined style={{  display: 'flex', marginLeft: '10px', marginTop: '7.5px', fontSize: 16 }} spin />} />
                            )}
                        </div>
                    </Upload>
                </S.WrapperUpload>
            </Form.Item>
        )}

      {place === "2" && 
        <Form.Item
            name="orderNumber"
            rules={[{ required: true, message: 'O campo deve conter de 7 a 8 dígitos', min: 7, max: 8 }]}
            normalize={normalizeOrderNumber}
            >
                <Input maxLength="8" placeholder="Nº do pedido" />
        </Form.Item> 
      }
      <Form.Item
          name="orderMsg"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
         <TextArea placeholder="Descrição"/>
      </Form.Item>
    </Form>
    </S.Wrapper>
  )
}

export default Step