class CONSTS {

  get ASSUNTOS() {
    return {
      1: [{name: 'Atraso transportadora', value: 1}],
      2: [{name: 'Andamento da compra', value: 2}],
      3: [{name: 'Alteração na compra', value: 3}],
      4: [{name: 'Pedido incompleto ', value: 4}],
      5: [{name: 'Desistência - Comprei Errado', value: 5}],
      6: [{name: 'Troca Do Pedido/Item', value: 6}],
      7: [{name: 'Comprovante de estorno', value: 7}],
      8: [{name: 'Pedido cancelado', value: 8}],
      9: [{name: 'Atendimento ao cliente', value: 9}],
      10: [{name: 'Consulta de produto', value: 10}],
      11: [{name: 'Trocas', value: 11}],
      12: [{name: 'Enviar receita', value: 12}],
      13: [{name: 'Marcas exclusivas', value: 13}],
      14: [{name: 'Marcas exclusivas', value: 14}],
      15: [{name: 'Marcas exclusivas', value: 15}],
      16: [{ name: 'App Viva Saúde', value: 16 }],
    }
  }
  
  get SOLICITACOES_LOJA_ONLINE() {
    return [
      { name: 'Meu pedido está em atraso', value: 1 },
      { name: 'Status do meu pedido', value: 2 },
      { name: 'Removeram um item do meu pedido', value: 3 },
      { name: 'Recebi o meu pedido com falta', value: 4 },
      { name: 'Desistência - comprei errado', value: 5 },
      { name: 'Desejo trocar o meu pedido', value: 6 },
      { name: 'Estorno não realizado', value: 7 },
      { name: 'Cancelamento - item indisponível', value: 8 },
      { name: 'Envio de receita', value: 12 },
      { name: 'Marcas Próprias - Qualidade do produto', value: 13 },
      { name: 'Marcas Próprias - Reações alérgicas', value: 14 },
      { name: 'Marcas Próprias - Bulas/Lista de ingredientes', value: 15 },
      { name: 'App Viva Saúde', value: 16 },
    ]
  }

  get SOLICITACOES_LOJA_FISICA() {
    return [
      { name: 'Ocorrências em loja', value: 9 },
      { name: 'Consulta de produtos em loja', value: 10 },
      { name: 'Troca de item - compra loja física', value: 11 },
      { name: 'Marcas Próprias - Qualidade do produto', value: 13 },
      { name: 'Marcas Próprias - Reações alérgicas', value: 14 },
      { name: 'Marcas Próprias - Bulas/Lista de ingredientes', value: 15 },
      { name: 'App Viva Saúde', value: 16 },
    ]
  }
  
  //serviço 3
 get statusSmart() {
    return {
      1: 551801,
      2: 551816,
      3: 551803,
      4: 551805,
      5: 551810,
      6: 554920,
      7: 551813,
      8: 551806,
      9: 551808,
      10: 551818,
      11: 551820,
      12: 667648,
      13: 795375,
      14: 795376,
      15: 795377,
      16: 1094840,
    }
  }

  gettemplateHtmlEmail({clientName, solicitacao, assunto, mensagem, arquivo}) {
    const nameSoli = [...this.SOLICITACOES_LOJA_ONLINE, ...this.SOLICITACOES_LOJA_FISICA].filter((soli) => String(soli.value) === String(solicitacao))
    const nameAssu = this.ASSUNTOS[solicitacao][0].name
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Email</title>
      <style>
        .snxFormContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: relative;
          flex: 1;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
     
        .snxFormCard {
          display: flex;
          padding:5px;
          flex: 1;
          background: #fff;
          justify-content: center;
          align-items: center;
          align-content: center;
          justify-items: center;
        }
      </style>
    </head>
    <body>
      <div class="snxFormContainer">
        <div class="snxFormCard">
          <div class="snxFormCardContent" style="display: flex;flex-direction: column;padding: 10px;max-height: 100%;min-height: 100px;padding-top: 20px;text-align: center;background-color: #fafafa;border: 1px dashed #e9e9e9;border-radius: 2px;min-width: 50%;border-radius: 10px;overflow-x: hidden;z-index: 9999;">
            <h1>Mensagem enviada através do formulário do site</h1>
            <p>O Cliente: <b>${clientName}</b>, encaminhou a mensagem abaixo com a solicitação: <b>${nameSoli[0].name}</b> e o assunto: <b>${nameAssu}</b> </p>
            <div class="snxformDescription" style="background: #fff;border: 1px dashed #e9e9e9;font-weight: 700;font-size: 15px;padding: 8px;margin-top:10px">
             <p style="margin: 5px 0;display: flex;justify-content:center;justify-items:center;align-content: center;align-items: center;"> ${mensagem} </p>
             </br>
             ${arquivo}
             </div>
          </div>
        </div>
      </div>
    </body>
    </html> 
    `
  }

  getFormatedTicketData(ticket) {
    return {
        "type": 2,
        "subject": ticket.assunto,
        "description":ticket.descricao,
        "category": "Formulario",
        "serviceFirstLevelId": ticket.statusSmart,
        "actions": [
          {type: 2, description: ticket.descricao}
        ],
        "createdBy": {
          "id": ticket.clientID
        },
        "clients":[{
          "id": ticket.clientID
        }],
      "customFieldValues":[{
          "customFieldId":54734,
          "customFieldRuleId":26079,
          "value": ticket.localSolicitacao
      },
      {
          "customFieldId": 20501,
          "customFieldRuleId": 9674,
          "value": ticket.numeroPedido
      },
      {
          "customFieldId": 20492,
          "customFieldRuleId": 9520,
          "items": [{
            "customFieldItem": ticket.SIGLA
          }]
      },
      {
        "customFieldId":54734,
        "customFieldRuleId":26079,
        "items": [
        {
             "customFieldItem": ticket.localSolicitacao
        }]
      },
      {
          "customFieldId": 73385,
          "customFieldRuleId": 36100,
          "items": [{
                "customFieldItem": "Formulario"
          }]
      }]
    }
  }

  get LOCAL_SOLICITACO() {
    return {
      1: "Forms Loja Física",
      2: "Forms Loja Online",
    }
  }
}

const ConstInstance = new CONSTS()
export default ConstInstance
