import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  flex: 1;
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  height: 10vh;
  background: ${({host}) => host === 'drogariapacheco' ? '#ef4f36' : '#242424'  };
  z-index: 1;
`;

export const WrapperBackground = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 10vh;
  height:30vh;
  z-index: 1;
  background: ${({host}) => host === 'drogariapacheco' ? '#fff' : '#badee7'  };
  border-bottom-right-radius: 45px;
  border-bottom-left-radius: 45px;
`;

export const WrapperBody = styled.div`
  display: flex;
  flex: 1;
  background: ${({host}) => host === 'drogariapacheco' ? '#ededed' : '#fff'  };
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  max-height: 80vh;
  overflow-y: auto;
  @media (max-width: 680px) {
    align-content: center;
    align-items: center;
    justify-items: center;
  }
`;
export const WrapperFooter = styled.div`
  display: flex;
  width: 100vw;
  height: 10vh;
  background: ${({host}) => host === 'drogariapacheco' ? '#addfea' : '#d34544'  };
  margin-top: 10px;
`;

export const WrapperSteps = styled.div`
    color: rgba(0,0,0,.85);
    max-height: 100%;
    z-index: 2;
    .ant-steps {
      display: inline-flex;
    }
  @media (max-width: 680px) {
    .ant-steps {
      display: none;
    }
  }
`;

export const TopWrapper = styled.div`
  height: 40;
  width: 40;
  line-height: '40px';
  border-radius: 4;
  background: '#1088e9';
  color: '#fff';
  text-align: 'center';
  font-size: 14;
`;