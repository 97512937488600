import ApiInstance from './api'

const ApiSaoPaulo = new ApiInstance('https://smart-api.smartnx.io', '74e78942d3b40abac2ae3d3c27c90fd18231bea286fec795a7740416f2b7d65c')
const ApiPacheco = new ApiInstance('https://smart-api.smartnx.io', '1b2554b6466ceebdabe6ec8e8ab74f88d20f7be0cd08d38e8c1627c6076b2c8f')

class Vtex {
  getFormatedOrders(numberOrder) {
  const apiSaoPaulo = [
    `v${numberOrder}dgsp-01`,
    `v${numberOrder}dgsp-02`,
    `v${numberOrder}dgsp-03`,
  ]
  const apiPacheco = [
    `v${numberOrder}dgpc-01`,
    `v${numberOrder}dgpc-02`,
    `v${numberOrder}dgpc-03`,
  ]
    return [apiSaoPaulo, apiPacheco]
  }
  
  async getOrder(number, cpf) {
      const [ordersSaoPaulo, ordersPacheco] = this.getFormatedOrders(number)
      const respsSaoPaulo = await Promise.all(ordersSaoPaulo.map(async (formatedNumber) =>  await ApiSaoPaulo.get(`api/v1/vtex/orders/${formatedNumber}`, { company_id: "6" })))
      const respsPacheco = await Promise.all(ordersPacheco.map(async (formatedNumber) =>  await ApiPacheco.get(`api/v1/vtex/orders/${formatedNumber}`, { company_id: "8" })))
      const respsOk = this.filterSuccessOrders([...respsSaoPaulo, ...respsPacheco], cpf)
      return respsOk
  }

  
  filterSuccessOrders(orders, cpf) {
    const formatedCpf = cpf.split('.').join('').replace('-','')
    let successOrders = orders.filter((order) => !!order.data)
    // verificar se pode trazer todos os status
    // successOrders = successOrders.filter((order) => order.data.status === 'invoiced')
    successOrders = successOrders.filter((order) => String(order.data.clientProfileData.document) === String(formatedCpf))
    return successOrders
  }

  
  async getOrderByCpf(cpf) {
    const resp = await ApiPacheco.get(`api/v1/vtex/clients/${cpf}/orders/last`, { company_id: "8" })
    const resp2 = await ApiSaoPaulo.get(`api/v1/vtex/clients/${cpf}/orders/last`, { company_id: "6" })
    return [resp, resp2]
  }
} 

const VtexInstance = new Vtex()
export default VtexInstance